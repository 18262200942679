export const faqData = [
  {
    title: 'Jak długo uczyłeś się programowania przed pierwszą pracą?',
    tags: 'nauka,programowanie,praca',
    content:
      'Zacząłem w 2014 roku, ale miałem po drodze kilka przerw. Najbardziej intensywny okres nauki przypada na 2016-2017 gdzie przez te dwa lata cisnąłem naprawdę solidnie. Kilka miesięcy przed dostaniem się do pracy zrealizowałem kilka projektów jako freelancer.',
  },
  {
    title: 'W czym programujesz?',
    tags: 'programowanie,nauka,kod,język',
    content:
      'Jestem Frontendowcem, więc na co dzień posługuję się HTMLem, CSSem, JavaScriptem. Znam też podstawy Pythona, Ruby, Go, a ostatnio rozwijam swoje umiejętności związane z Node.js (w uproszczeniu – backendowym stackiem JavaScriptowym).',
  },
  {
    title: 'Jakie studia skończyłeś?',
    tags: 'studia,nauka',
    content:
      'Studiowałem przez rok kulturoznawstwo na UAM, potem przeniosłem się na Dziennikarstwo i skończyłem je z tytułem magistra na specjalności „Zarządzanie komunikacją w biznesie”. Nie kończyłem żadnych studiów, podyplomówek czy kursów stacjonarnych związanych z programowaniem.',
  },
  {
    title: 'Na jakim sprzęcie pracujesz?',
    tags: 'sprzęt,komputer,monitor',
    content: `Obecnie pracuję na MacBooku Pro 16" 2019 z procesorem Intel Core i7 2.6 GHz i 16GB RAMu. Do tego korzystam z monitora Dell UltraSharp 27", Apple Magic Keyboard II, Myszki Logitech MX Masters 3, mikrofonu Rode Podcaster`,
  },
  {
    title: 'Na jakim stanowisku pracujesz?',
    tags: 'praca,netguru',
    content:
      'Jestem Senior Frontend Developerem oraz Team Leaderem w Netguru. Od dwóch lat jestem też ekspertem w tzw. Discovery, co w dużym uproszczeniu oznacza kontakty z potencjalnymi klientami w celu rekomendowania rozwiązań technologicznych, określania zakresu projektu i wielu innych aspektów współpracy',
  },
  {
    title: 'Od czego zacząć naukę programowania?',
    tags: 'programowanie,nauka,początki',
    content:
      'Od początku! A tak serio – sposobów może być wiele, ale warto na początku poczytać trochę na temat samego programowania i dziedzin, którymi możemy się potencjalnie zajmować. Frontend, Backend, Machine Learning, Devops, Aplikacje Mobilne – tego jest naprawdę sporo. Jeśli jednak zdecydujesz się na frontend, to nagrałem o tym odcinek, który na bank Ci pomoże.',
    link: 'https://youtu.be/U1eEkWZqoDQ',
    linkText: 'Obejrzyj odcinek',
  },
  {
    title: 'Co frontendowiec musi umieć?',
    tags: 'frontend,skille,umiejętności',
    content:
      'Dobre pytanie! Frontend to jedna z tych dziedzin programowania, która zmienia się bardzo dynamicznie. To co było aktualne 2-3 lata temu, obecnie odchodzi w niepamięć. Aby pozostać na bieżąco warto czytać artykuły na Medium, słuchać podcastów i śledzić ciekawe projekty na GitHubie. Mam też odcinek, w którym opowiadam co warto wiedzieć o Frontendzie w 2020 roku.',
    link: 'https://youtu.be/XlpgE8B-ekc',
    linkText: 'Obejrzyj odcinek',
  },
  {
    title: 'Czy pracujesz wyłącznie zdalnie?',
    tags: 'praca,netguru',
    content:
      'Głównie tak – siedzę w chacie z Józefem 🐈 na rękach i kodzę. Ale staram się 2-3 razy w miesiącu odwiedzać biuro, żeby nie zdziczeć do reszty 😂. W naszej firmie nie ma obowiązku pojawiania się w biurze, więc jest to wyłącznie moja decyzja.',
  },
  {
    title: 'Co warto umieć przed pierwszą pracą?',
    tags: 'praca,frontend,skille',
    content:
      'Początki dla juniora bywają bardzo stresujące – sporo nowych narzędzi i technologii, które potrafią przytłoczyć. Warto zapoznać się dość dobrze z GIT-em, npm-em, warto też zahaczyć o takie narzędzia jak Postman czy Insomnia. Sprawdź mój odcinek by dowiedzieć się więcej na ten temat.',
    link: 'https://youtu.be/lRYmR-uDFww',
    linkText: 'Obejrzyj odcinek',
  },
  {
    title: 'Ile czasu zajmuje nauka programowania?',
    tags: 'nauka,programowanie,czas',
    content:
      'Programowanie to nie coś, co można ogarnąć od A do Z, a sama nauka programowania to nie sprint tylko maraton. Zawsze będzie coś czego nie wiemy, dlatego jeśli przeszkadza Ci świadomość, że nigdy nie przestaniesz się uczyć, to nie zaczynaj przygody z programowaniem. Jeśli jednak chcesz to jakoś porządniej zaplanować i oszacować ile +/- zajmie Ci czasu dojście do poziomu juniorskiego, to zapraszam do obejrzenia odcinka w linku.',
    link: 'https://youtu.be/1Jr6e5UmMUs',
    linkText: 'Obejrzyj odcinek',
  },
  {
    title: 'Z jakiego theme korzystasz w VSC/Webstorm?',
    tags: 'edytor,programowanie',
    content: 'Czy to w VSC czy w Webstorm zawsze korzystam z Material Theme w odmianie Ocean (choć nazwa może się lekko różnić np. Deep Ocean). Font, którego używam to Rational',
  },
];
