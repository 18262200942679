import React from 'react';
import MainTemplate from 'templates/MainTemplate/MainTemplate';
import Faq from "templates/Faq/Faq"

const IndexPage = () => (
  <MainTemplate>
    <Faq/>
  </MainTemplate>
);

export default IndexPage;
