import styled from 'styled-components';
import { Input } from 'components/Input/Input';
import { Paragraph } from 'components/Typo';

export { Paragraph } from 'components/Typo';

export const Wrapper = styled.div`
  padding: 80px 30px;
  position: relative;
  min-height: 100vh;

  ${({ theme }) => theme.mq.desktop} {
    padding: 120px 80px;
    display: grid;
    grid-gap: 0 50px;
    grid-template-columns: repeat(3, 1fr);
  }

  ${({ theme }) => theme.mq.bigDesktop} {
    padding: 120px 150px;
  }
`;

export const ConfettiImage = styled.img`
  display: none;
  object-fit: contain;
  position: fixed;
  left: -50px;
  top: 74vh;
  z-index: -1;
  transform: rotate(25deg);

  ${({ theme }) => theme.mq.desktop} {
    display: block;
  }
`;

export const Header = styled.h1`
  position: relative;

  ${({ theme }) => theme.addBackground('70px', '150px', '-10px', '-20px')};

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.xl};
    ${({ theme }) => theme.addBackground('150px', '250px', '-30px', '-30px')};
  }
`;

export const StyledInput = styled(Input)`
  width: 90%;
`;

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin: 20px 0 50px;
  background: ${({ theme }) => theme.grey};

  ${({ theme }) => theme.mq.tablet} {
    margin: 50px 0;
  }
`;

export const ItemsWrapper = styled.div`
  ${({ theme }) => theme.mq.desktop} {
    padding: 150px 0 0 50px;
    grid-column: 2 / span 2;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-gap: 50px 40px;
  }

  ${({ theme }) => theme.mq.bigDesktop} {
    padding: 150px 0 0 25px;
    grid-gap: 50px 60px;
  }
`;

export const ItemWrapper = styled.div`
  margin: 15px 0 50px;

  ${({ theme }) => theme.mq.desktop} {
    margin: 0;
  }
`;

export const ItemTitle = styled.h2`
  margin: 0;
  font-size: ${({ theme }) => theme.font.size.s};
`;

export const ItemTags = styled(Paragraph)`
  display: inline;
  font-weight: bold;
  font-size: ${({ theme }) => theme.font.size.xxs};
`;

export const Link = styled(Paragraph)`
  font-weight: bold;
  margin: 0;
  color: black;
`;

export const SideSection = styled.div`
  ${({ theme }) => theme.mq.desktop} {
    position: fixed;
    grid-column: 1;
    grid-row: 1;
    max-width: 25%;
  }
`;
