import React, { useState } from 'react';
import confettiImage from 'assets/images/pattern_confetti.svg';
import { faqData } from 'data/faq';
import {
  Wrapper,
  Header,
  InputWrapper,
  StyledInput,
  ItemTags,
  ItemTitle,
  Paragraph,
  ItemsWrapper,
  ConfettiImage,
  Link,
  ItemWrapper,
  SideSection,
} from './Faq.styles';

const Faq = () => {
  const [faqInputValue, setFaqInputValue] = useState('');

  const handleInputChange = (e) => {
    setFaqInputValue(e.target.value);
  };

  const doesContainPhrase = (phrase, object) => {
    const keys = Object.keys(object);

    return keys.find((key) => {
      if (typeof object[key] === 'string') {
        return object[key].toLowerCase().includes(phrase.toLowerCase());
      }
      return [];
    });
  };

  return (
    <Wrapper>
      <ConfettiImage src={confettiImage} />
      <SideSection>
        <Header>o co chcesz zapytać?</Header>
        <InputWrapper>
          <StyledInput
            name="faq"
            type="text"
            value={faqInputValue}
            onChange={handleInputChange}
            placeholder="np. dokąd nocą tupta jeż?"
          />
        </InputWrapper>
      </SideSection>
      <ItemsWrapper>
        {faqData
          .filter((item) => doesContainPhrase(faqInputValue, item))
          .map((item) => (
            <ItemWrapper key={item.title}>
              <ItemTitle>{item.title}</ItemTitle>
              {item.tags.split(',').map((tag) => (
                <ItemTags key={tag}>#{tag} </ItemTags>
              ))}
              <Paragraph>{item.content}</Paragraph>
              <Link
                as="a"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.linkText}
              </Link>
            </ItemWrapper>
          ))}
      </ItemsWrapper>
    </Wrapper>
  );
};

export default Faq;
